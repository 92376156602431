<template>
  <sw-top-page-bar
    v-model="activeTab"
    is-tab-view
    :tab-view-list="tabList"
  >
    <template v-if="activeTab">
      <div
        v-if="activeTab.title === tabList[0].title"
        :key="0"
      >
        <div class="mt-75">
          <div>
            <b-row>
              <b-col sm="12"
                     md="4"
              >
                <b-card class="border cursor-pointer"
                        :class="{ 'border-primary': filters.taskStatus === 'O' }"
                        @click="changeTaskViewStatus('O')"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h2 class="mb-25 font-weight-bolder">
                        {{ stats.O }}
                      </h2>
                      <span>{{ $t('task.status.O') }}</span>
                    </div>
                    <b-avatar size="40"
                              variant="light-primary"
                    >
                      <sw-icon icon="CircleIcon" />
                    </b-avatar>
                  </div>
                  <b-overlay :show="loading"
                             no-wrap
                             spinner-variant="primary"
                  />
                </b-card>
              </b-col>

              <b-col sm="12"
                     md="4"
              >
                <b-card class="border cursor-pointer"
                        :class="{ 'border-primary': filters.taskStatus === 'D' }"
                        @click="changeTaskViewStatus('D')"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h2 class="mb-25 font-weight-bolder">
                        {{ stats.D }}
                      </h2>
                      <span>{{ $t('task.status.D') }}</span>
                    </div>
                    <b-avatar size="40"
                              variant="light-success"
                    >
                      <sw-icon icon="CheckIcon" />
                    </b-avatar>
                  </div>
                  <b-overlay :show="loading"
                             no-wrap
                             spinner-variant="primary"
                  />
                </b-card>
              </b-col>

              <b-col sm="12"
                     md="4"
              >
                <b-card class="border cursor-pointer"
                        :class="{ 'border-primary': filters.taskStatus === 'ND' }"
                        @click="changeTaskViewStatus('ND')"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h2 class="mb-25 font-weight-bolder">
                        {{ stats.ND }}
                      </h2>
                      <span>{{ $t('task.status.ND') }}</span>
                    </div>
                    <b-avatar size="40"
                              variant="light-danger"
                    >
                      <sw-icon icon="XIcon" />
                    </b-avatar>
                  </div>
                  <b-overlay :show="loading"
                             no-wrap
                             spinner-variant="primary"
                  />
                </b-card>
              </b-col>
            </b-row>

            <!--            <b-button-group-->
            <!--              class="flex-wrap"-->
            <!--              size="sm"-->
            <!--            >-->
            <!--              <b-button-->
            <!--                :variant="filters.taskStatus === 'O' ? 'primary' : 'outline-primary'"-->
            <!--                @click="changeTaskViewStatus('O')"-->
            <!--              >-->
            <!--                <sw-icon icon="CircleIcon"-->
            <!--                         class="mr-25"-->
            <!--                />-->
            <!--                {{ $t('task.status.O') }}-->
            <!--              </b-button>-->
            <!--              <b-button-->
            <!--                :variant="filters.taskStatus === 'D' ? 'primary' : 'outline-primary'"-->
            <!--                @click="changeTaskViewStatus('D')"-->
            <!--              >-->
            <!--                <sw-icon icon="CheckIcon"-->
            <!--                         class="mr-25"-->
            <!--                />-->
            <!--                {{ $t('task.status.D') }}-->
            <!--              </b-button>-->
            <!--              <b-button-->
            <!--                :variant="filters.taskStatus === 'ND' ? 'primary' : 'outline-primary'"-->
            <!--                @click="changeTaskViewStatus('ND')"-->
            <!--              >-->
            <!--                <sw-icon icon="XIcon"-->
            <!--                         class="mr-25"-->
            <!--                />-->
            <!--                {{ $t('task.status.ND') }}-->
            <!--              </b-button>-->
            <!--            </b-button-group>-->
          </div>
        </div>

        <!--    Section: Filters    -->
        <sw-filters
          v-if="!hideFilters"
          :shorts="[
            {
              title: $t('TaskTypes'),
              value: () => filters.selectedTypes
                ? filters.selectedTypes === -1
                  ? $t('All')
                  : taskTypes.find(({ id }) => id === filters.selectedTypes).name
                : '',
              clear: () => {
                filters.selectedTypes = -1
                reloadTasks(pagination.currentPage, pagination.perPage)
              }
            },
            {
              title: $t('TasksAssignedTo'),
              type: 'avatar',
              value: () => filters.assignedUsers,
              clear: () => {
                filters.assignedUsers = []
                changeAssignedUsers([]); reloadTasks(pagination.currentPage, pagination.perPage)
              }
            },
            {
              title: $t('ChooseTags'),
              value: () => {
                let valueList = []

                valueList = filters.contactTags.map(({ name }) => name)

                return valueList.join(', ')
              },
              clear: () => { filters.contactTags = []; reloadTasks(pagination.currentPage, pagination.perPage) }
            },
            {
              value: () => filters.isDelayed ? $t('ShowIsDelayed') : null,
              clear: () => { filters.isDelayed = false; reloadTasks(pagination.currentPage, pagination.perPage) }
            },
            {
              value: () => filters.showUnsignedTasks ? $t('ShowUnsignedTasks') : null,
              clear: () => { filters.showUnsignedTasks = false; reloadTasks(pagination.currentPage, pagination.perPage) }
            },
            {
              value: () => filters.isToday ? $t('ShowIsToday') : null,
              clear: () => { filters.isToday = false; reloadTasks(pagination.currentPage, pagination.perPage) }
            },
            {
              title: $t('SelectPlanedTasksRange'),
              value: () => filters.timeRange,
              clear: () => {
                filters.timeRange = '';
                reloadTasks(pagination.currentPage, pagination.perPage)
              }
            },
            {
              title: $t('TaskEndTermSelect'),
              value: () => filters.updatedAt,
              clear: () => {
                filters.updatedAt = '';
                reloadTasks(pagination.currentPage, pagination.perPage)
              }
            },
          ]"

          :res="[
            {
              title: filters.selectedTypes
                ? filters.selectedTypes === -1
                  ? $t('All')
                  : taskTypes.find(({ id }) => id === filters.selectedTypes).name
                : '',
              value: () => pagination.totalRecords,
            }
          ]"
        >
          <b-row>
            <b-col
              md="12"
              class="mb-75"
            >
              <label for="">{{ $t('TaskTypes') }}</label>

              <b-form-radio-group
                v-model="filters.selectedTypes"
                :options="taskTypes"
                value-field="id"
                text-field="name"
                disabled-field="disabled"
                @change="reloadTasks()"
              />
              <!--              <div>-->
              <!--                <b-badge-->
              <!--                  v-for="type in taskTypes"-->
              <!--                  :key="`type_${type.id}`"-->
              <!--                  class="mr-25 mb-25 p-50 cursor-pointer"-->
              <!--                  :variant="filters.selectedTypes.includes(type.id) ? 'primary' : 'light-primary'"-->
              <!--                  @click="toggleActiveType(type.id)"-->
              <!--                >-->
              <!--                  {{ type.name }}-->
              <!--                </b-badge>-->
              <!--              </div>-->
            </b-col>
            <b-col
              md="4"
              class="mb-50 pb-md-0"
            >
              <sw-select
                class="mb-0"
                :name="$t('TasksAssignedTo')"
              >
                <assigned-users
                  :value="[...filters.assignedUsers]"
                  @input="changeAssignedUsers"
                />
              </sw-select>
            </b-col>
            <b-col
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_TAGS])"
              md="4"
              class="mb-50 pb-md-0"
            >
              <sw-select
                class="mb-0"
                :name="$t('ChooseTags')"
              >
                <v-select
                  v-model="filters.contactTags"
                  multiple
                  class="mb-1"
                  :options="tags"
                  label="name"
                  @input="reloadTasks"
                >
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                  <template #option="{ name, color }">
                    <div
                      class="color-dot"
                      style="border: 1px solid white"
                      :style="{ backgroundColor: color }"
                    />
                    {{ name }}
                  </template>
                  <template #selected-option="{ name, color }">
                    <div
                      class="color-dot"
                      style="border: 1px solid white"
                      :style="{ backgroundColor: color }"
                    />
                    {{ name }}
                  </template>
                </v-select>
              </sw-select>
            </b-col>
            <b-col md="4">
              <sw-select :name="$t('SelectPlanedTasksRange')"
                         :tip="$t('SpecifyTheTimeRangeOfScheduledTasks')"
              >
                <sw-select-fpr
                  :is-empty="filters.timeRange"
                  @clear="filters.timeRange = ''; reloadTasks(pagination.currentPage, pagination.perPage)"
                >
                  <flat-pickr
                    ref="timeRange"
                    v-model="filters.timeRange"
                    class="form-control"
                    :config="{ dateFormat: 'd-m-Y', locale: getCalendarLocale($i18n.locale), mode: 'range' }"
                    @on-close="reloadTasks(pagination.currentPage, pagination.perPage)"
                  />
                </sw-select-fpr>
              </sw-select>
            </b-col>
            <b-col v-if="['D', 'ND'].includes(filters.taskStatus)"
                   md="4"
            >
              <sw-select :name="$t('TaskEndTermSelect')">
                <sw-select-fpr
                  :is-empty="filters.updatedAt"
                  @clear="filters.updatedAt = ''; reloadTasks(pagination.currentPage, pagination.perPage)"
                >
                  <flat-pickr
                    ref="timeRange"
                    v-model="filters.updatedAt"
                    class="form-control"
                    :config="{ dateFormat: 'd-m-Y', locale: getCalendarLocale($i18n.locale), mode: 'range' }"
                    @on-close="reloadTasks(pagination.currentPage, pagination.perPage)"
                  />
                </sw-select-fpr>
              </sw-select>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <b-form-checkbox
                v-model="filters.showUnsignedTasks"
                variant="primary"
                @change="reloadTasks(pagination.currentPage, pagination.perPage)"
              >
                {{ $t('ShowUnsignedTasks') }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="filters.taskStatus === 'O'"
                   md="3"
            >
              <b-form-checkbox
                v-model="filters.isDelayed"
                variant="primary"
                :disbaled="filters.disableIsDelayed"
                @change="reloadTasks(pagination.currentPage, pagination.perPage)"
              >
                {{ $t('ShowIsDelayed') }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="filters.taskStatus === 'O'"
                   md="3"
            >
              <b-form-checkbox
                v-model="filters.isToday"
                variant="primary"
                :disbaled="filters.disableIsToday"
                @change="reloadTasks(pagination.currentPage, pagination.perPage)"
              >
                {{ $t('ShowIsToday') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </sw-filters>

        <div class="my-50">
          <b-button
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_TASKS], [$viewRanks.SHOW_UPPER_HEADER_CONTACT_TASK]) && checkRequiredPermissions([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE ], [
              $roles.CONTACT_THREAD_TASK_ADD,
            ])"
            variant="primary"
            size="sm"
            class="mr-50"
            @click="openTaskModal"
          >
            <sw-icon icon="PlusIcon" />
            {{ $t('Add') }}
          </b-button>

          <b-button
            variant="primary"
            size="sm"
            :disabled="exportLoading"
            @click="getRecordsToExport"
          >
            <span v-if="!exportLoading"><feather-icon icon="DownloadIcon" />Export</span>
            <span v-else>
              <b-spinner
                small
                variant="light"
              />
            </span>
          </b-button>
        </div>

        <b-card>
          <div class="mb-1">
            <span v-if="filters.taskStatus === 'O'"
                  v-b-tooltip
                  :title="selectedTasks.length ? '' : $t('SelectTasksToAllow')"
            >
              <b-button-group
                class="flex-wrap"
                size="sm"
              >
                <b-button
                  variant="outline-primary"
                  size="sm"
                  disabled
                >
                  {{ $t('SignAs') }}:
                </b-button>
                <b-button
                  variant="flat-success"
                  size="sm"
                  :disabled="!selectedTasks.length"
                  @click="onChangeSelectedTasksStatuses('D')"
                >
                  <feather-icon icon="CheckIcon" />
                  {{ $t('task.status.D') }}

                  <span v-if="selectedTasks.length">({{ selectedTasks.length }})</span>
                </b-button>
                <b-button
                  variant="flat-danger"
                  size="sm"
                  :disabled="!selectedTasks.length"
                  @click="onChangeSelectedTasksStatuses('ND')"
                >
                  <feather-icon icon="XIcon" />
                  {{ $t('task.status.ND') }}

                  <span v-if="selectedTasks.length">({{ selectedTasks.length }})</span>
                </b-button>
              </b-button-group>
            </span>
          </div>
          <!-- eslint-disable -->
          <sw-table
            table-id="f9ba60041cce44f4b07a2306ff393df0"
            :pagination="pagination"
            show-search
            show-sorter
            :fields.async="fields"
            :sorter="sorter"
            show-columns
            @change-search-query="search = $event"
            @change-pagination="Object.assign(pagination, $event)"
            @reload-content="reloadTasks"
            @set-fields="fields = $event"
            @sort-changed="Object.assign(sorter, $event); reloadTasks()"
          >
            <template #table>
              <b-table
                striped
                responsive
                hover
                :items="tasks"
                :fields="getFields"
                :sort-by.sync="sorter.sortBy"
                :sort-desc.sync="sorter.sortDesc"
                :no-local-sorting="true"
                :per-page="pagination.perPage"
                class="mb-0"
                :busy="loading"
                :style="{ minHeight: tableSize(tasks, 250) }"
                @row-clicked="openViewModal"
              >
                <template #head()="{ label }">
                  {{ $t(`${label}`) }}
                </template>
                <template #cell(id)="{ index }">
                  #{{ getOrderNo(index, pagination, sorter) }}
                </template>
                <template #head(select)>
                  <b-form-checkbox v-model="selectAll"
                                   variant="primary"
                                   @change="selectAllTasks"
                  />
                </template>
                <template #cell(select)="{ item }">
                  <b-form-checkbox v-model="selectedTasks"
                                   variant="primary"
                                   :value="item.id"
                  />
                </template>
                <template #cell(contactThreadTaskReport)="{ item }">
                  <div v-if="item.contactThreadTaskReport" class="d-flex align-items-center">
                    <b-button class="btn-icon mr-50"
                              variant="gradient-primary"
                              size="sm"
                              @click="openModal('viewModal', $t('PreviewReport'), '', { ...item, ...item.contactThreadTaskReport })"
                    >
                        <sw-icon icon="EyeIcon" />
                    </b-button>

                    <documents-list v-if="item.contactThreadTaskReport.files && item.contactThreadTaskReport.files.length" :documents="item.contactThreadTaskReport.files" />
                  </div>
                  <feather-icon v-else
                                icon="MinusIcon"
                  />
                </template>
                <!--   Task Type   -->
                <template #cell(contactThread)="data">
                  <div v-if="data.value">
                    <div class="d-flex align-items-center mb-n25">
                      <router-link v-b-tooltip
                                   :title="getUserName(data.value.contact)"
                                   :class="{ 'deleted-text': data.value.contact.deletedAt }"
                                   :to="`/contact/${data.value.contact.id}/thread/${data.value.id}/tasks`"
                      >
                        {{ getUserName(data.value.contact) | truncate(40) }}
                      </router-link>

                      <b-button
                        variant="flat-success"
                        :disabled="!!data.value.contact.deletedAt"
                        :to="`/contact/${data.value.contact.id}/thread/${data.value.id}/tasks`"
                        target="_blank"
                        class="btn-icon rounded mr-25"
                        size="sm"
                      >
                        <feather-icon
                          class="text-success"
                          icon="ExternalLinkIcon"
                        />
                      </b-button>
                    </div>

                    <small class="text-muted">{{ data.value.name | truncate(24, '...') }}</small>
                  </div>
                  <feather-icon
                    v-else
                    icon="MinusIcon"
                  />
                </template>
                <template
                  #cell(contactThreadTaskType)="data"
                >
                  <b-badge
                    :id="`type_${data.index}`"
                    variant="light-primary"
                    style="padding: 6px 10px"
                  >
                    <!--     Task type content     -->
                    {{ data.value.name }}
                    <feather-icon
                      v-if="data.item.address"
                      icon="NavigationIcon"
                    />
                    <!--     Task type content     -->
                  </b-badge>
                  <!--     Task type badge tooltip     -->
                  <b-tooltip
                    v-if="data.item.address"
                    :target="`type_${data.index}`"
                    placement="top"
                    variant="dark"
                  >
                    {{ data.item.address }}
                  </b-tooltip>
                  <!--     Task type badge tooltip     -->
                </template>
                <!--   Task Status   -->
                <template #cell(status)="data">
                  <b-badge
                    :id="`status_${data.index}`"
                    :variant="taskTypeVariant(data.value)"
                    style="padding: 6px 10px"
                  >
                    <!--     Task type content     -->
                    {{ $t(`task.status.${data.value}`) }}
                    <!--     Task type content     -->
                  </b-badge>
                </template>
                <!--   Task Desc   -->
                <template
                  #cell(content)="data"
                >
                  <span :inner-html.prop="data.value | truncate(40, '...')" />
                </template>
                <!--                <template #cell(files)="data">-->
                <!--                  <documents-list-->
                <!--                    v-if="data.value && data.value.length"-->
                <!--                    :documents="data.value"-->
                <!--                    :index="data.index"-->
                <!--                  />-->
                <!--                  <feather-icon-->
                <!--                    v-else-->
                <!--                    icon="MinusIcon"-->
                <!--                  />-->
                <!--                </template>-->

                <template #cell(action)="data">
                  <div
                    :id="data.id"
                    class="text-center"
                  >
                    <b-dropdown
                      id="dropdown-grouped"
                      dropleft
                      no-caret
                      variant="flat"
                    >
                      <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" />
                      </template>
                      <div v-if="data.item && data.item.status && data.item.status === 'O'">
                        <b-dropdown-item @click="changeTaskStatus(data.item.id, 'D', data.index, data.item, data.item.contactThread)">
                          <dropdown-item-label icon="CheckIcon"
                                               :label="$t('task.status.D')"
                          />
                        </b-dropdown-item>
                        <b-dropdown-item @click="changeTaskStatus(data.item.id, 'ND', data.index, data.item, data.item.contactThread)">
                          <dropdown-item-label icon="XIcon"
                                               :label="$t('task.status.ND')"
                          />
                        </b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item
                          @click="openViewModal(data.item)"
                        >
                          <dropdown-item-label icon="EyeIcon"
                                               :label="$t('Preview')"
                          />
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="checkRequiredPermissions([
                            $roles.CONTACT_THREAD_TASK_EDIT,
                            { role: $roles.CONTACT_THREAD_TASK_EDIT_SELF, data: data.item, field: 'createdBy', userId: currentUser.id }
                          ]) && isTimeLimitReached(data.item.createdAt.date, null, 'contactThreadTaskDeleteTime')"
                          @click="editTask(data.item, data.item.contactThread)"
                        >
                          <dropdown-item-label icon="EditIcon"
                                               :label="$t('Edit')"
                          />
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="checkRequiredPermissions([
                            $roles.CONTACT_THREAD_TASK_DELETE,
                          ])"
                          @click="deleteTask(data.item.id, data.index, data.item.contactThread)"
                        >
                          <dropdown-item-label icon="TrashIcon"
                                               :label="$t('Delete')"
                          />
                        </b-dropdown-item>
                      </div>
                      <div v-else>
                        <b-dropdown-item @click="editTask(data.item, data.item.contactThread, true)">
                          <dropdown-item-label icon="RepeatIcon"
                                               :label="$t('RepeatTask')"
                          />
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="data.item.contactThreadTaskType.isReportRequired && data.item.contactThreadTaskReport"
                          @click="openModal('viewModal', $t('PreviewReport'), '', { ...data.item, ...data.item.contactThreadTaskReport })"
                        >
                          <dropdown-item-label icon="ArchiveIcon"
                                               :label="$t('ShowReport')"
                          />
                        </b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item @click="openViewModal(data.item)">
                          <dropdown-item-label icon="EyeIcon"
                                               :label="$t('Preview')"
                          />
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>

                <template #cell()="row">
                  <table-default-cell
                    :field="row.field"
                    :value="row.value"
                  />
                </template>

                <!--      Empty      -->
                <template #empty>
                  <empty-content />
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner
                      class="align-middle"
                      variant="primary"
                    />
                  </div>
                </template>
              </b-table>
            </template>
          </sw-table>
        </b-card>

        <b-modal
          v-model="showAddAllReport"
          :title="$t('AddReport')"
          hide-footer
          @hide="taskReport = { content: null, files: [], taskStatus: 'D' }"
        >
          <div>
            <b-form-group :label="$t('Content')"
                          class="form-label-group"
            >
              <b-form-textarea
                v-model="taskReport.content"
                rows="5"
                no-resize
              />
            </b-form-group>
            <!-- Ipload files -->
            <DragDropUpload
              ref="dragdropupload"
              :is-refresh="false"
              @onDeleteFiles="taskReport.files = []"
              @onChangeFiles="changeFiles"
            />
            <!--  Footer  -->
            <div class="d-flex justify-content-end pt-1 mt-1 border-top">
              <b-button
                size="sm"
                variant="secondary"
                @click="showAddAllReport = false"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                class="ml-25"
                size="sm"
                variant="primary"
                :disabled="!taskReport.content"
                @click="onCreateReport"
              >
                {{ $t('Add') }}
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>

      <div
        v-if="activeTab.title === tabList[1].title"
        :key="1"
      >
        <calendar />
      </div>
      <div
        v-if="activeTab.title === tabList[2].title"
        :key="2"
      >
        <tasks-reports />
      </div>
    </template>
  </sw-top-page-bar>
</template>

<script>
import {
  BBadge, BButtonGroup, BFormRadioGroup, BFormTextarea, BTable, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  DELETE_CONTACT_TASK,
  GET_GLOBAL_TASKS,
  GET_TASKS,
  GET_THREAD_TAGS,
  OPEN_MODAL,
  UPDATE_CONTACT_TASK, UPLOAD_FILES,
} from '@/@constants/mutations'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import { mapGetters } from 'vuex'
import * as roles from '@/helpers/permissions'
import timeLimiter from '@/helpers/timeLimiter'
import { loadFromLocalStorage, saveToLocalStorage } from '@/helpers/localStorage'
import useSwUtils from '@/composable/useSwUtils'
import Calendar from '@/pages/Calendar.vue'
import TasksReports from '@/pages/reports/tabs/Tasks.vue'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import axiosIns from '@/libs/axios'
import flatPickr from 'vue-flatpickr-component'
import i18n from '@/libs/i18n'
import XLSX from 'xlsx'
import { formatDate } from '@core/utils/filter'
import DocumentsList from '@/views/components/DocumentsList.vue'

export default {
  name: 'SwTasks',
  components: {
    DocumentsList,
    flatPickr,
    BFormTextarea,
    DragDropUpload,
    Calendar,
    AssignedUsers,
    BTable,
    BBadge,
    BTooltip,
    BButtonGroup,
    BFormRadioGroup,
    vSelect,
    TasksReports,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    hideFilters: {
      type: Boolean,
      default: false,
    },
    propsUser: {
      type: Object,
      required: false,
      default: null,
    },
    delayedTasks: {
      type: Boolean,
      required: false,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    thread: {
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    funnel: {
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    funnelPoint: {
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    contacts: {
      default: () => [],
    },
  },
  setup() {
    const { isTimeLimitReached } = useSwUtils()

    return {
      isTimeLimitReached,
    }
  },
  data: () => ({
    exportLoading: false,
    tasks: [],
    tags: [],
    selectAll: false,
    selectedTasks: [],

    taskReport: {
      taskStatus: 'D',
      content: '',
      files: [],
    },
    showAddAllReport: false,

    filters: {
      timeRange: '',
      updatedAt: '',
      selectedTypes: '',
      taskStatus: 'O',
      assignedUsers: [],
      showTodayAndOverdue: false,
      disableShowTodayAndOverdue: false,
      contactTags: [],
      disableIsDelayed: true,
      disableIsToday: true,
      isDelayed: false,
      isToday: false,
      showUnsignedTasks: false,
    },
    taskTypes: [],
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    sorter: {
      sortBy: 'endAt',
      sortDesc: false,
    },
    stats: {
      D: 0,
      ND: 0,
      O: 0,
    },
    fields: [
      {
        key: 'select',
        label: 'Select',
        visible: true,
      },
      {
        key: 'id', label: '#', visible: true, sortBy: 'id',
      },
      {
        key: 'contactThread',
        label: 'task.Thread',
        visible: true,
      },
      {
        key: 'contactThreadTaskType',
        label: 'task.Type',
        visible: true,
      },
      {
        key: 'status',
        label: 'task.Status',
        visible: true,
      },
      {
        key: 'updatedAt',
        label: 'TaskEndedAt',
        forStatus: ['D', 'ND'],
        visible: true,
      },
      {
        key: 'contactThreadTaskReport',
        label: 'timeline.Task_report',
        forStatus: ['D', 'ND'],
        visible: true,
      },
      {
        key: 'content',
        label: 'task.Description',
        visible: true,
      },
      {
        key: 'files',
        label: 'task.Files',
        tag: 'file',
        visible: true,
      },
      {
        key: 'startAt',
        label: 'task.DateStart',
        sortBy: 'startAt',
        visible: true,
      },
      {
        key: 'endAt',
        label: 'task.DateEnd',
        sortBy: 'endAt',
        visible: true,
      },
      {
        key: 'createdAt',
        label: 'task.Created_Time',
        visible: true,
      },
      {
        key: 'assignedUser',
        label: 'task.AssignedUser',
        tag: 'avatar',
        visible: true,
      },
      {
        key: 'sendReminderEmail',
        label: 'task.ReminderEmail',
        visible: true,
      },
      {
        key: 'sendReminderSms',
        label: 'task.ReminderSms',
        visible: true,
      },
      {
        key: 'createdBy',
        label: 'task.Principal',
        tag: 'avatar',
        visible: true,
      },
      {
        key: 'action',
        label: 'task.Action',
        visible: true,
      },
    ],
    search: '',
    loading: false,
    roles,

    activeTab: null,
    tabList: [
      {
        title: 'timeline.Task',
        icon: 'ListIcon',
      },
      {
        title: 'Calendar',
        icon: 'CalendarIcon',
      },
      {
        title: 'TasksReports',
        icon: 'BarChart2Icon',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      reloadContent: 'modal/getReloadContent',
      currentUser: 'auth/getCurrentUser',
    }),
    getFields() {
      return this.fields.filter(e => e.visible).filter(e => (e.forStatus && e.forStatus.includes(this.filters.taskStatus)) || !e.forStatus)
    },
  },
  watch: {
    funnel(n, o) { if (n !== o) this.reloadTasks() },
    funnelPoint(n, o) { if (n !== o) this.reloadTasks() },
    thread(n, o) { if (n !== o) this.reloadTasks() },
    contacts(n, o) { if (n !== o) this.reloadTasks() },
    selectedTasks: {
      handler() {
        this.selectAll = this.selectedTasks.length === this.tasks.length
      },
      deep: true,
    },
    reloadContent(newValue) {
      if (newValue) {
        this.reloadTasks()
      }
    },
    '$i18n.locale': {
      deep: true,
      handler() {
        this.reloadTaskTypes()
      },
    },
  },
  async mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.activeTab = this.tabList[0]

    if (this.$route.params.isToday === false) {
      this.filters.isDelayed = true
    }

    if (this.$route.params.isToday === true) {
      this.filters.isToday = true
    }

    this.filters.assignedUsers = []
    if (loadFromLocalStorage('tasks_assigned_filter')) {
      this.filters.assignedUsers = loadFromLocalStorage('tasks_assigned_filter')
    }

    if (this.delayedTasks) {
      this.filters.showTodayAndOverdue = true
    }

    if (this.propsUser) {
      this.changeAssignedUsers([this.propsUser])
    } else if (this.propsUser === false) {
      this.changeAssignedUsers([])
      return
    }
    await this.loadTaskTypes()
    this.onGetContactTags()
    this.reloadTasks()
  },
  methods: {
    timeLimiter,
    selectAllTasks() {
      this.selectedTasks = this.selectAll ? this.tasks.map(task => task.id) : []
    },
    toggleActiveType(typeId) {
      const index = this.filters.selectedTypes.findIndex(e => e === typeId)

      if (index > -1) {
        this.$delete(this.filters.selectedTypes, index)
      } else {
        this.filters.selectedTypes.push(typeId)
      }

      this.reloadTasks()
    },
    changeFiles(files) {
      // this.taskReport.files = files
      this.taskReport.files.push(...files)
    },
    openTaskModal() {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'task',
          modalTitle: i18n.t('AddTask'),
          okButtonTitle: i18n.t('Save'),
          size: 'lg',
        })
    },
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę', data) {
      this.$root.$emit('bv::hide::popover')
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
          threadFor: this.threadId,
          data,
        })
    },
    openViewModal(data) {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'viewModal',
        modalTitle: this.$i18n.t('PreviewTask'),
        data,
      })
    },
    onGetContactTags() {
      this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        .then(res => {
          this.tags = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    changeAssignedUsers(payload) {
      this.filters.assignedUsers = payload || []
      saveToLocalStorage('tasks_assigned_filter', payload)
      this.reloadTasks()
    },
    // Edited
    reloadTasks(page = this.pagination.currentPage, perPage = this.pagination.perPage) {
      this.loading = true

      if (this.funnel) {
        this.filters.funnel = this.getObjectId(this.funnel)
      }
      if (this.funnelPoint) {
        this.filters.funnelPoint = this.getObjectId(this.funnelPoint)
      }
      if (this.thread) {
        this.filters.thread = this.getObjectId(this.thread)
      }
      if (this.contacts) {
        this.filters.contacts = (this.contacts || []).mapKeys('id')
      }

      this.$nextTick(() => {
        const payload = {
          page: this.pagination.totalRecords < perPage ? 1 : page,
          perPage,
          filters: this.filters,
          sorter: this.sorter,
          search: this.search,
        }

        this.$store.dispatch(`globalTasks/${GET_GLOBAL_TASKS}`, payload)
          .then(res => {
            setTimeout(() => {
              this.pagination.totalRecords = res?.data.totalItemCount
              this.tasks = res?.data.items
              this.stats = res.stats
              this.loading = false
            }, 500)
          })
          .catch(err => {
            this.loading = false
            // eslint-disable-next-line no-prototype-builtins
            if (err.hasOwnProperty('message')) {
              return
            }

            this.showToast('error', this.$i18n.t('ProblemOccured'))
          })
      })
    },
    async reloadTaskTypes() {
      const res = await this.$store.dispatch(`typeSettings/${GET_TASKS}`)

      this.taskTypes = [
        {
          id: -1,
          name: this.$i18n.t('All'),
        },
        ...res,
      ]
    },
    async loadTaskTypes() {
      const res = await this.$store.dispatch(`typeSettings/${GET_TASKS}`)

      this.taskTypes = [
        {
          id: -1,
          name: this.$i18n.t('All'),
        },
        ...res,
      ]

      this.filters.selectedTypes = -1

      // if (!this.filters.selectedTypes.length) this.filters.selectedTypes = res.map(type => type.id.toString())
    },
    // Edited
    changeTaskStatus(taskId, statusValue, taskIndex, item, threadId) {
      const data = {
        id: taskId.toString(),
        status: statusValue,
      }
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          if (item.contactThreadTaskType.isReportRequired) {
            this.$store.commit(`modal/${OPEN_MODAL}`,
              {
                modalType: 'report',
                modalTitle: this.$i18n.t('NewReport'),
                okButtonTitle: this.$i18n.t('AddReport'),
                threadFor: this.getObjectId(threadId),
                data: { ...item, statusValue, taskIndex },
              })
          } else {
            this.$store.dispatch(`singleContact/${UPDATE_CONTACT_TASK}`, { updateTaskData: data, threadId: this.getObjectId(threadId) })
              .then(() => {
                this.reloadTasks()
                this.showToast('success', this.$i18n.t('StatusUpdated'))
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
          }
        })
    },
    deleteTask(taskId, taskIndex, threadId) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          this.$store.dispatch(`singleContact/${DELETE_CONTACT_TASK}`, { taskId, threadId: this.getObjectId(threadId) })
            .then(() => {
              this.$delete(this.tasks, taskIndex)
              this.showToast('success', this.$i18n.t('alerts.Removed'))
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },

    async onChangeSelectedTasksStatuses(taskStatus = 'D') {
      const selectedTasksObjects = this.selectedTasks.map(id => this.tasks.find(task => task.id === id)).filter(Boolean)
      const isReportRequired = selectedTasksObjects.some(task => task?.contactThreadTaskType?.isReportRequired)

      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), isReportRequired ? this.$i18n.t('SomeTasksRequireReports') : '')
      if (!confirmed) return

      if (isReportRequired) {
        this.taskReport = { content: '', files: [], taskStatus }
        this.showAddAllReport = true
      } else {
        await this.changeTasksStatuses(selectedTasksObjects.map(task => ({ id: task.id, status: taskStatus })))
      }
    },
    async onCreateReport() {
      const selectedTasksObjects = this.selectedTasks.map(id => this.tasks.find(task => task.id === id)).filter(Boolean)
      const { taskStatus, ...reportPayload } = this.taskReport

      if (reportPayload.files?.length) {
        const filesPayload = { uploadedFiles: reportPayload.files, type: 'report' }
        const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filesPayload)

        reportPayload.files = files || []
      }

      await this.changeTasksStatuses(selectedTasksObjects.map(task => {
        if (task?.contactThreadTaskType?.isReportRequired) {
          return {
            contactThreadTaskReport: reportPayload,
            id: task.id,
            status: taskStatus,
          }
        }

        return { id: task.id, status: taskStatus }
      }))

      this.showAddAllReport = false
    },
    async changeTasksStatuses(tasksPayload = []) {
      try {
        await axiosIns.patch('1/contacts/threads/tasks/changeStatus', tasksPayload)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.reloadTasks()
        this.selectedTasks = []
        this.selectAll = false
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    changeTaskViewStatus(value) {
      if (['D', 'ND'].includes(value)) {
        this.sorter.sortBy = 'updatedAt'
        this.sorter.sortDesc = true
      } else {
        this.sorter.sortBy = 'endAt'
        this.sorter.sortDesc = false
      }

      this.filters.isDelayed = false
      this.filters.isToday = false

      if (value !== 'O') {
        this.filters.showTodayAndOverdue = false
        this.filters.disableShowTodayAndOverdue = true
      } else {
        this.filters.disableShowTodayAndOverdue = false
      }
      this.filters.taskStatus = value
      this.reloadTasks()
    },
    taskTypeVariant(value) {
      let variant = 'light-primary'
      if (value === 'D') variant = 'light-success'
      else if (value === 'O') variant = 'light-info'
      else if (value === 'ND') variant = 'light-danger'
      return variant
    },
    editTask(task, threadId, repeat = false) {
      const taskData = task

      if (repeat) delete taskData.id

      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'task',
          size: 'lg',
          modalTitle: this.$i18n.t('EditTask'),
          okButtonTitle: this.$i18n.t('Save'),
          threadFor: this.getObjectId(threadId),
          editedData: taskData,
        })
    },
    async getRecordsToExport() {
      this.exportLoading = true
      try {
        const { total } = await this.loadExportTasks(1, 1)
        const chunksToLoad = Math.ceil(total / 75)
        const requests = new Array(chunksToLoad).fill(null)
        const response = await Promise.all(requests.map((_, index) => this.loadExportTasks(index + 1, 75)))

        const items = []
        response.forEach(res => {
          items.push(...res?.items.map(exportedItem => ({
            ...exportedItem,
          })))
        })

        this.$nextTick(() => {
          this.exportRecords(items)
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /* eslint-disable */
      async exportRecords(recordsList) {
          const wb = XLSX.utils.book_new()

          const order = [
              { key: 'id', label: 'ID' },
              { key: 'contact', label: 'FirstNameAndLastName' },
              { key: 'contactThread', label: 'task.Thread' },
              { key: 'link', label: 'Link' },
              { key: 'contactThreadTaskType', label: 'task.Type' },
              { key: 'status', label: 'task.Status' },
              { key: 'updatedAt', label: 'TaskEndedAt' },
              { key: 'contactThreadTaskReport', label: 'timeline.Task_report' },
              { key: 'content', label: 'task.Description' },
              { key: 'files', label: 'task.Files' },
              { key: 'startAt', label: 'task.DateStart' },
              { key: 'endAt', label: 'task.DateEnd' },
              { key: 'createdAt', label: 'task.Created_Time' },
              { key: 'assignedUser', label: 'task.AssignedUser' },
              { key: 'sendReminderEmail', label: 'task.ReminderEmail' },
              { key: 'sendReminderSms', label: 'task.ReminderSms' },
              { key: 'createdBy', label: 'task.Principal' },
          ]

          const aoaToExport = [
              ...recordsList.sort((a, b) => (b.status > a.status) ? 1 : -1).map(t => order.map(({ key }) => {
                  if (key === 'contact') {
                      if (t?.contactThread) return `${this.getUserName(t?.contactThread?.contact)}`
                      else return `-`
                  }

                  else if (key === 'contactThread') {
                      if (t[key]) return `${t[key]?.name || ''}`
                      else return `-`
                  }

                  else if (key === 'contactThreadTaskType' && t[key]) {
                      return t[key]?.name || '-'
                  }

                  else if (key === 'contactThreadTaskReport' && t[key]) {
                      return t[key]?.content || '-'
                  }

                  else if (key === 'files') {
                      return t[key]?.length || 0
                  }

                  else if (key === 'assignedUser' || key === 'createdBy') {
                      return t[key] ? this.getUserName(t[key]) : '-'
                  }

                  else if (key === 'link') {
                      return t.contactThread && t?.contactThread?.contact?.id ? `${window.location.origin}/contact/${t.contactThread.contact.id}/thread/${t.contactThread.id}/timeline` : '-'
                  }

                  else if (key === 'status') return this.$i18n.t(`task.status.${t[key]}`)
                  else if (typeof t[key] === 'boolean') return t[key] ? 1 : 0
                  else if (key === 'updatedAt' && t[key]?.date) {
                      return t.status !== 'O' ? this.formatDate(t[key].date, {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric'
                      }, this.$i18n.locale) : '-'
                  }
                  else if (t[key]?.date) return this.formatDate(t[key].date, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale)

                  return t[key] ?? ''
              }).flat(Infinity)),
          ]

          const headers = order.map(({ label }) => this.$i18n.t(label))
          aoaToExport.unshift(headers)

          const ws = XLSX.utils.aoa_to_sheet(aoaToExport);
          XLSX.utils.book_append_sheet(wb, ws, `${this.$i18n.t('Tasks')}`);
          XLSX.writeFile(wb, `${this.$i18n.t('Tasks')}.xlsx`);

          this.exportLoading = false
      },
      formatDate,
      async loadExportTasks(page = 1, perPage = 1) {
          if (this.funnel) {
              this.filters.funnel = this.getObjectId(this.funnel)
          }
          if (this.funnelPoint) {
              this.filters.funnelPoint = this.getObjectId(this.funnelPoint)
          }
          if (this.thread) {
              this.filters.thread = this.getObjectId(this.thread)
          }
          if (this.contacts) {
              this.filters.contacts = (this.contacts || []).mapKeys('id')
          }

          const payload = {
              page,
              perPage,
              filters: this.filters,
              sorter: this.sorter,
              search: this.search,
              exporter: true
          }

          const { data } = await this.$store.dispatch(`globalTasks/${GET_GLOBAL_TASKS}`, payload)

          return { total: data.totalItemCount, items: data.items }
      },
  },
}
</script>
